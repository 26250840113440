import styled, { keyframes } from 'styled-components';

export const MainDigitisationContainer = styled.main`
    margin: 10px;
    height: 97.5%;
    background: #FFFFFF;
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    max-height: 97.5%;
    border: 1px solid #E4E4E4;
    transition: all .25s ease-in;
    position: relative;
`;

export const MainDigitisationHeader = styled.header`
    background: #ECE9FF;
    background: linear-gradient(135deg, #ECE9FF 0%, #D1CFFF 100%);
    padding: 20px;
    border-radius: 10px 10px 0 0;
    padding-bottom: 12.5px;
    position: relative;
`;

export const NameHeader = styled.h1`
    font-size: 17.5px;
    color: #3A2CA0;
    font-weight: 700;
`;

export const SimpleRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const SimpleColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const CallButton = styled.button`
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 5px 17.5px;
    font-size: 12.5px;
    border-radius: 7.5px;
    color: #714FFF;
    height: 32.5px;
    font-weight: 500;
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
    border: 1px solid #E4E4E4;
`;

export const InfoBlock = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 11px;
    font-weight: 500;
`;

export const InfoBreaker = styled.div`
    width: 2px;
    height: 12.5px;
    background: #9393A54D;
`;

export const StepText = styled.div`
    color: #714FFF;
    font-weight: 700;
    font-size: 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const CommonHeading = styled.h2`
    color: #000000;
    font-weight: 700;
    font-size: 15px;
`;

export const CommonHelpText = styled.h3`
    font-size: 12.5px;
    font-weight: 600;
    color: #444;
    margin-bottom: 5px;
`;

export const SelectionGrid = styled.div`
    margin-bottom: 7.5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;
`;

export const SelectionItem = styled.div`
    background: #CFC8FF66;
    color: #714FFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    font-size: 12.5px;
    border-radius: 5px;
    font-weight: 600;
`;

export const NetworkCenterOption = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ChipButton = styled.button`
    height: 32.5px;
    width: 32.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    border-radius: 5px;
`;

export const NewDigitisationButton = styled.button`
    width: 100%;
    cursor: ${({ disabled, loading }) => ((disabled || loading) ? 'not-allowed' : 'pointer')};
    border: 1.5px solid ${({ disabled }) => (disabled ? '#ddd' : '#714FFF')};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 13px;
    background-color: ${({ primary, disabled }) => (primary ? (disabled ? '#ddd' : '#714FFF') : 'transparent')};
    color: ${({ primary, disabled }) => (primary ? (disabled ? '#aaa' : '#fff') : (disabled ? '#aaa' : '#714FFF'))};
    font-weight: ${({ primary }) => (primary ? 700 : 600)};
    border-radius: 7px;
`;

export const BreadcrumbChip = styled.div`
    position: absolute;
    right: 5px;
    top: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    height: 75%;
    border-radius: 7.5px;
    padding: 0 10px;
    font-weight: 600;
    border: ${({ primary, myColor }) => (primary ? `1.5px solid ${myColor || '#32AD54'}` : `1.5px solid ${myColor || '#3F8CFF'}`)};
    color: ${({ primary, myColor }) => (primary ? `${myColor || '#32AD54'}` : `${myColor || '#3F8CFF'}`)};
`;

export const AltOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 45vh;
    background: #fff;
    border-radius: 7.5px;
    overflow-y: scroll;
    margin: 5px 0;
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
    border: 1px solid #E4E4E4;
    padding: 7.5px;
    row-gap: 7.5px;
    height: fit-content;
`;

export const AltOptionHeading = styled.h3`
    font-size: 15px;
    color: #333;
    font-weight: 600;
    margin-top: 20px;
`;

export const AltOption = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
    cursor: pointer;
    border-radius: 7.5px;
    transition: all .25s ease-in;

    &:hover {
        background-color: #eee;
    }
`;

export const FinishImage = styled.img`
    width: 100%;
    transform: translate(-50%, -40%);
    position: absolute;
    top: 0;
    left: 50%;
`;

const spinAnimation = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
    border: 3px solid ${({ primary }) => primary ? '#f3f3f3' : '#4F34D2'};
    border-top: 3px solid ${({ primary }) => primary ? '#4F34D2' : '#f3f3f3'};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: ${spinAnimation} 1s linear infinite;
`;

export const CommonHyperlink = styled.div`
    color: ${({ disabled }) => disabled ? '#bbb' : '#714FFF'};
    cursor: pointer;
    font-size: 10px;
    font-weight: 700;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};

    display: inline-block;
    text-decoration: none;
    transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;

    &:hover {
        color: ${({ disabled }) => disabled ? '#bbb' : '#3A2CA0'};
    }

    &:active {
        color: ${({ disabled }) => disabled ? '#bbb' : '#3A2CA0'};
    }
`;

export const CommonFileOverlay = styled.div`
    background: #FFFFFF;
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
    border-radius: 10px;
    border: 1px solid #E4E4E4;
    position: fixed;
    right: .5vh;
    padding: 12.5px;
    top: 1.25vh;
    height: 97.5vh;
    width: 44.5vw;
`;

export const CommonLoader = styled.div`
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    cursor: not-allowed;
    background: #ffffffaa;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1;
`;

export const PartnerImage = styled.img`
    height: 40px;
    width: 40px;
    border: 1px solid #ddd;
    border-radius: 50%;
    transform: ${({ idx }) => `translateX(-${idx * 12.5}px)`};
    transition: all .25s ease-in;

    &:hover {
        z-index: 2;
        border-color: #000;
    }
`;

export const AbsoluteHelpText = styled.div`
    background: ${({ background }) => background};
    position: absolute;
    top: 0;
    left: 20px;
    width: fit-content;
    padding: 1.5px 7.5px;
    border-radius: 0 0 5px 5px;
    font-weight: 600;
    font-size: 10px;
    color: #fff;
`;

export const AbsoluteHelpBadge = styled(SimpleRow)`
    background: ${({ color }) => `${color}33`};
    color: ${({ color }) => color};
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(110%, -50%);
    font-size: 10px;
    font-weight: 500;
    padding: 0 5px;
    border-radius: 2.5px;
    margin: 0;
    line-height: 12.5px;
    border: ${({ color }) => `1px solid ${color}`};
    white-space: nowrap;
`;

export const HelpBadge = styled(SimpleRow)`
  background: ${({ color }) => `${color}33`};
  color: ${({ color }) => color};
  font-size: 10px;
  font-weight: 500;
  padding: 0 5px;
  border-radius: 2.5px;
  margin: 0;
  line-height: 12.5px;
  border: ${({ color }) => `1px solid ${color}`};
  white-space: nowrap;
  display: inline-flex; 
`;


export const CustomOption = styled.button`
    color: #714FFF;
    padding: 5px;
    font-weight: 600;
    font-size: 12.5px;
`;

export const RedIndicator = styled.div`
  margin-left: 10px;
  color: #FF754C;
  border: 1px solid #FF754C;
  border-radius: 10px;
  padding: 2.5px 5px;
  font-weight: 700;
  background: #fff;
  font-size: 8px;
  cursor: pointer;
`;

export const RedCrossIndicator = styled(RedIndicator)`
  margin-right: 10px;
  margin-left: 0;
  text-decoration: line-through;
`;