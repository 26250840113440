export const defaultState = {
  filters: [
    // { name: 'All', isSelected: false },
    { id: 1, name: 'Unattented', isSelected: false, scope: ['doctors'] },
    { id: 2, name: 'Ongoing', isSelected: false, scope: ['doctors'] },
    // { id: 3, name: 'Followups', isSelected: false, scope: ['doctors'] },
    { id: 4, name: 'Completed', isSelected: false, scope: ['doctors'] },
    { id: 5, name: 'Digitized', isSelected: false, scope: ['transcribers'] },
    { id: 6, name: 'Pending', isSelected: false, scope: ['transcribers'] },
    { id: 7, name: 'Invalid', isSelected: false, scope: ['transcribers'] },
    { id: 8, name: 'MyCases', isSelected: false, scope: [] },
    { id: 9, name: 'Corona Cases', isSelected: false, scope: ['doctors'] },
    {
      id: 10,
      name: 'Scheduled Sessions',
      isSelected: false,
      scope: ['doctors'],
    },
    {
      id:11,
      name :'Suspicious Users',
      isSelected:false,
      scope:['transcribers']
    },
    {
      id:12,
      name :'Ops Upload',
      isSelected:false,
      scope:['transcribers']
    },
  ],
  selectedSponsorId: null,
  selectedDoctorId: null,
  selectedSoruce: null,
  fromDate:null,
  toDate:null
};

export default function filters(state = defaultState, action) {
  switch (action.type) {
    case 'TOGGLE_FILTER': {
      const filters = [...state.filters];
      filters[action.payload.index].isSelected =
        !filters[action.payload.index].isSelected;
      return Object.assign({}, state, {
        filters,
      });
    }
    case 'SET_SELECTED_SPONSOR_ID': {
      return Object.assign({}, state, {
        selectedSponsorId: action.payload.selectedSponsorId,
      });
    }
    case 'SET_SELECTED_DOCTOR_ID': {
      return Object.assign({}, state, {
        selectedDoctorId: action.payload.selectedDoctorId,
      });
    }
    case 'SET_SELECTED_SOURCE': {
      console.log({ action }, 'SET_SELECTED_SOURCE');
      return { ...state, selectedSoruce: action.payload.selectedSource };
    }
    case 'SET_FROM_DATE': {
      console.log({ action }, 'SET_FROM_DATE');
      return Object.assign({}, state, {
        fromDate: action.payload.fromDate,
      });
    }
    case 'SET_TO_DATE': {
      console.log({ action }, 'SET_TO_DATE');
      return Object.assign({}, state, {
        toDate: action.payload.toDate,
      });
    }
    case 'LOGOUT_USER': {
      return Object.assign({}, state, defaultState);
    }
    default:
      return state;
  }
}
